import React from "react"
import { Link } from "gatsby"
import Worker from "../images/logo.png"

const Logo = () => {
  return (
    <div className="w-full text-center bg-black text-center py-2">
      <Link to="/">
        <img
          src={Worker}
          alt="Spiritual Worker"
          style={{ maxHeight: "120px" }}
          className="mx-auto"
        />
      </Link>
    </div>
  )
}

export default Logo
